<template>
  <HXContentCard title="Términos y Condiciones">
    <b-modal
      body-class="myclass"
      title="Editar Términos y condiciones"
      id="my-modal"
      size="lg"
      hide-footer
    >
      <div class="text-left">
        <form @submit.prevent="saveData()">
          <div class="row">
            <div class="col-6">
              <label for="informacionPersonalCorreo" class="form-label ml-1"
                >País</label
              >
              <b-form-select
                v-model="row.paisId"
                required
                value-field="id"
                text-field="descripcion"
                :options="listaPaises"
              ></b-form-select>
            </div>
            <div class="col-12 mt-3">
              <label for="informacionPersonalCorreo" class="form-label ml-1"
                >Texto</label
              >
              <quill-editor
                style="height: 280px"
                :content="row.texto"
                @change="onEditorChange($event)"
              ></quill-editor>
            </div>
          </div>
          <div class="" style="margin-top: 80px">
            <div class="d-flex justify-content-end">
              <vs-button
                icon
                danger
                class="mx-1"
                @click.prevent="$bvModal.hide('my-modal')"
                >Cancelar</vs-button
              >
              <vs-button icon primary type="submit" class="mx-1"
                >Guardar</vs-button
              >
            </div>
          </div>
        </form>
      </div>
    </b-modal>

    <card>
      <div class="text-right px-4 mt-4 responsive-xl-md">
        <div class="d-flex justify-content-end">
          <vs-button class="mx-1 text-right" @click="showModal(null)"
            ><i class="fas fa-plus-circle mr-1"></i>Agregar</vs-button
          >
        </div>
      </div>
      <!-- button-sm -->
      <div
        class="text-right px-4 mt-4 pb-0 mb-0 responsive-sm"
        style="margin-bottom: -73px !important"
      >
        <div class="d-flex justify-content-end">
          <vs-button
            success
            class=""
            style="
              background-color: rgb(54, 153, 100) !important;
              display: block !important;
            "
            @click="$router.push('Empresas_agregar')"
            ><i class="fas fa-plus"></i
          ></vs-button>
        </div>
      </div>
      <!-- end button-sm -->
      <div class="table-responsive px-4 mt-3">
        <table
          id="datatable_empresa"
          class="table table-bordered table-responsive-md table-striped text-center"
        >
          <thead>
            <tr>
              <th>País</th>
              <th>Texto</th>
              <th>Opciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in listaItems" :key="item.id">
              <td>{{ item.pais.descripcion }}</td>
              <td><div v-html="item.texto.substr(1, 50) + '...'"></div></td>
              <td>
                <span @click="showModal(item)" class="pointer"
                  ><i class="fas fa-solid fa-pen px-1 text-success"></i>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </card>
  </HXContentCard>
</template>
<script>
import { core } from "../../../config/pluginInit";
const Swal = require("sweetalert2");
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

export default {
  components: {
    quillEditor,
  },
  name: "DataTable",
  data() {
    return {
      listaItems: [],
      logoEmpresa: "",
      itemId: null,
      row: {},
      listaPaises: [],
    };
  },
  async mounted() {
    this.$isLoading(true);
    await this.getData();
    await this.getDataPaises();
    core.index();
    window.$("#datatable_empresa").DataTable({
      language: {
        url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
      },
    });
    this.$isLoading(false);
  },
  methods: {
    async getDataPaises() {
      try {
        let res = await this.$store.dispatch("hl_post", {
          path: "Pais/List",
        });
        if (res.length > 0) {
          this.listaPaises = res;
        } else {
          this.listaPaises = [];
        }
      } catch (error) {
        this.listaItems = [];
        console.log("err", error);
      }
    },
    onEditorChange({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.row.texto = html;
    },
    showModal(item) {
      if (item != undefined) {
        this.row = item;
      } else {
        this.row = {};
      }
      this.$bvModal.show("my-modal");
    },
    async saveData() {
      try {
        this.$isLoading(true);

        let res = await this.$store.dispatch("hl_post", {
          path: "TerminosYCondiciones/TerminosYCondiciones",
          data: this.row,
        });
        this.$isLoading(false);
        if (res) {
          Swal.fire("Listo!", "Proceso finalizado correctamente.", "success");
          this.$bvModal.hide("my-modal");
          this.row = {};
        } else {
          Swal.fire("Error!", "Error, intentalo nuevamente.", "error");
        }
      } catch (error) {
        this.$isLoading(false);
        console.log("error", error);
        Swal.fire("Error!", "Error: " + error, "error");
      }
    },
    async deleteRow(id) {
      Swal.fire({
        title: "Seguro eliminar registro?",
        text: "Después de eliminarlo no podrás recuperarlo!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si!",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          //let loader = this.$loading.show();
          await this.$store.dispatch("hl_get", {
            path: "Empresa/delete/" + id,
          });

          //console.log("res", res);
          //loader.hide()
          await this.getData();

          Swal.fire("Listo!", "Registro eliminado correctamente.", "success");
        }
      });
    },
    async getData() {
      //console.log(this.persona.empresa.id);
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "TerminosYCondiciones/TerminosYCondiciones",
        });
        console.log("get terminos y condiciones...", res);
        if (res.length > 0) {
          this.listaItems = res;
        } else {
          this.listaItems = [];
        }
      } catch (error) {
        this.listaItems = [];
        console.log("err", error);
      }
    },
  },
};
</script>
<style scoped>
.modal-dialog,
.modal-content {
  /* 80% of window height */
  height: 80%;
}

.modal-body {
  /* 100% = dialog height, 120px = header + footer */
  max-height: calc(100% - 120px);
  overflow-y: scroll;
}

.myclass > div {
  height: 500px;
}
</style>
